$margin1: 0.25rem;
$badgeHeight: 1.5rem;

.skillCompact {
  max-height: calc(($badgeHeight + $margin1) * 2); // バッジ2行分
}

.scheduleTypeName {
  width: 130px; // 作業名を10文字程度で丸める幅
}

.badge {
  height: $badgeHeight;
  line-height: $badgeHeight;
}

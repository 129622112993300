.itemContainerBorder {
  border-radius: 4px;
}

.itemContainerPadding {
  padding: 1px 3px;
}

.innerBox {
  height: 100%;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}
